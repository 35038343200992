export const assets = {
    USDC:
    {
        denom: 'uusdc',
        denom_name: 'axlUSDC',
        decimal: 6,
        allow_decimal: 2,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            min_deposit: 50,
            min_fee: 20.5,
        },
        polygon:
        {
            tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            contractAddress: '0xfBA3b7Bb043415035220b1c44FB4756434639392',
            min_deposit: 10,
            min_fee: 1.5,    
        },
        fantom:
        {
            tokenAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
            contractAddress: '0x261036923F4aAF1322a3173921fCBdaE51352CAe',
            min_deposit: 10,
            min_fee: 1.5,     
        },                                
    },
    USDCe:
    {
        denom: 'uusdc',
        denom_name: 'axlUSDC',
        decimal: 6,
        allow_decimal: 2,
        type: 'token',
        avalanche:
        {
            tokenAddress: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
            contractAddress: '0xbb8a6436e0E9A22bb7F1dC76aFB4421D8195620E',
            min_deposit: 10,
            min_fee: 1.5,
        },                              
    },
    ETH:
    {
        denom: 'weth-wei',
        denom_name: 'WETH',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            contractAddress: '0x1B7239aB106C7bAc8176607A33F57F6f02007aDe',
            min_deposit: 0.1,
            min_fee: 0.0204,
        },                                
    },
    BNB:
    {
        denom: 'wbnb-wei',
        denom_name: 'WBNB',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        binance:
        {
            tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            contractAddress: '0xD9256999cCACDb685D17b4a196219666d576C8CB',
            min_deposit: 0.015,
            min_fee: 0.007,
        },                                
    },     
    AVAX:
    {
        denom: 'wavax-wei',
        denom_name: 'WAVAX',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        avalanche:
        {
            tokenAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
            contractAddress: '0x1B7239aB106C7bAc8176607A33F57F6f02007aDe',
            min_deposit: 0.2,
            min_fee: 0.090,
        },                                
    },
    MATIC:
    {
        denom: 'wmatic-wei',
        denom_name: 'WMATIC',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        polygon:
        {
            tokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
            contractAddress: '0x1B7239aB106C7bAc8176607A33F57F6f02007aDe',
            min_deposit: 10,
            min_fee: 3,
        },                                
    },
    FTM:
    {
        denom: 'wftm-wei',
        denom_name: 'WFTM',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        fantom:
        {
            tokenAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
            contractAddress: '0x1B7239aB106C7bAc8176607A33F57F6f02007aDe',
            min_deposit: 20,
            min_fee: 6,
        },                                
    },
    GLMR:
    {
        denom: 'wglmr-wei',
        denom_name: 'WLMR',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        moonbeam:
        {
            tokenAddress: '0xAcc15dC74880C9944775448304B263D191c6077F',
            contractAddress: '0x1B7239aB106C7bAc8176607A33F57F6f02007aDe',
            min_deposit: 10,
            min_fee: 2.8,
        },                                
    },
    WETH:
    {
        denom: 'weth-wei',
        denom_name: 'WETH',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            min_deposit: 0.1,
            min_fee: 0.0204,
        },                                
    },
    WAVAX:
    {
        denom: 'wavax-wei',
        denom_name: 'WAVAX',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        avalanche:
        {
            tokenAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
            min_deposit: 0.2,
            min_fee: 0.090,
        },                                
    },
    WBNB:
    {
        denom: 'wbnb-wei',
        denom_name: 'WBNB',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        binance:
        {
            tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            min_deposit: 0.015,
            min_fee: 0.007,
        },                                
    },    
    WMATIC:
    {
        denom: 'wmatic-wei',
        denom_name: 'WMATIC',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        polygon:
        {
            tokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
            min_deposit: 10,
            min_fee: 3,
        },                                
    },
    WFTM:
    {
        denom: 'wftm-wei',
        denom_name: 'WFTM',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        fantom:
        {
            tokenAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
            min_deposit: 20,
            min_fee: 6,
        },                                
    },
    WGLMR:
    {
        denom: 'wglmr-wei',
        denom_name: 'WLMR',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        moonbeam:
        {
            tokenAddress: '0xAcc15dC74880C9944775448304B263D191c6077F',
            min_deposit: 10,
            min_fee: 2.8,
        },                                
    },
    axlUSDC:
    {
        denom: 'uusdc',
        denom_name: 'axlUSDC',
        decimal: 6,
        allow_decimal: 2,
        type: 'token',
        binance:
        {
            tokenAddress: '0x4268B8F0B87b6Eae5d897996E6b845ddbD99Adf3',
            min_deposit: 10,
            min_fee: 1.5,
        },        
        avalanche:
        {
            tokenAddress: '0xfaB550568C688d5D8A52C7d794cb93Edc26eC0eC',
            min_deposit: 10,
            min_fee: 1.5,
        },
        polygon:
        {
            tokenAddress: '0x750e4C4984a9e0f12978eA6742Bc1c5D248f40ed',
            min_deposit: 10,
            min_fee: 1.5,    
        },
        fantom:
        {
            tokenAddress: '0x1B6382DBDEa11d97f24495C9A90b7c88469134a4',
            min_deposit: 10,
            min_fee: 1.5,     
        },
        moonbeam:
        {
            tokenAddress: '0xCa01a1D0993565291051daFF390892518ACfAD3A',
            min_deposit: 10,
            min_fee: 1.5,     
        },                                        
    },
    axlUSDT:
    {
        denom: 'uusdt',
        denom_name: 'axlUSDT',
        decimal: 6,
        allow_decimal: 2,
        type: 'token',
        binance:
        {
            tokenAddress: '0x4818B684a810fC023C32bB6292da8D508Bd906EF',
            min_deposit: 10,
            min_fee: 1.5,
        },        
        avalanche:
        {
            tokenAddress: '0xF976ba91b6bb3468C91E4f02E68B37bc64a57e66',
            min_deposit: 10,
            min_fee: 1.5,
        },
        polygon:
        {
            tokenAddress: '0xCeED2671d8634e3ee65000EDbbEe66139b132fBf',
            min_deposit: 10,
            min_fee: 1.5,    
        },
        fantom:
        {
            tokenAddress: '0xd226392C23fb3476274ED6759D4a478db3197d82',
            min_deposit: 10,
            min_fee: 1.5,     
        },
        moonbeam:
        {
            tokenAddress: '0xDFd74aF792bC6D45D1803F425CE62Dd16f8Ae038',
            min_deposit: 10,
            min_fee: 1.5,     
        },                                        
    },
    USDT:
    {
        denom: 'uusdt',
        denom_name: 'axlUSDT',
        decimal: 6,
        allow_decimal: 2,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            min_deposit: 50,
            min_fee: 20.5,
        },                             
    },
    axlDAI:
    {
        denom: 'dai-wei',
        denom_name: 'axlDAI',
        decimal: 18,
        allow_decimal: 2,
        type: 'token',
        binance:
        {
            tokenAddress: '0xF02eaeEa1350DAD8fc7A66d6BddB25876243ed1F',
            min_deposit: 10,
            min_fee: 1.5,
        },        
        avalanche:
        {
            tokenAddress: '0xC5Fa5669E326DA8B2C35540257cD48811F40a36B',
            min_deposit: 10,
            min_fee: 1.5,
        },
        polygon:
        {
            tokenAddress: '0xDDc9E2891FA11a4CC5C223145e8d14B44f3077c9',
            min_deposit: 10,
            min_fee: 1.5,    
        },
        fantom:
        {
            tokenAddress: '0xD5d5350F42CB484036A1C1aF5F2DF77eAFadcAFF',
            min_deposit: 10,
            min_fee: 1.5,     
        },
        moonbeam:
        {
            tokenAddress: '0x14dF360966a1c4582d2b18EDbdae432EA0A27575',
            min_deposit: 10,
            min_fee: 1.5,     
        },                                        
    },
    DAI:
    {
        denom: 'dai-wei',
        denom_name: 'axlDAI',
        decimal: 18,
        allow_decimal: 2,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
            min_deposit: 50,
            min_fee: 20.5,
        },                             
    },
    axlFRAX:
    {
        denom: 'frax-wei',
        denom_name: 'axlFRAX',
        decimal: 18,
        allow_decimal: 2,
        type: 'token',
        binance:
        {
            tokenAddress: '0x3FF4cb8EC5EC5eBBfD3424401D962F0627a67Cac',
            min_deposit: 10,
            min_fee: 1.5,
        },        
        avalanche:
        {
            tokenAddress: '0x4914886dBb8aAd7A7456D471EAab10b06d42348D',
            min_deposit: 10,
            min_fee: 1.5,
        },
        polygon:
        {
            tokenAddress: '0x53Adc464b488bE8C5d7269B9ABBCe8bA74195C3a',
            min_deposit: 10,
            min_fee: 1.5,    
        },
        fantom:
        {
            tokenAddress: '0xbE71e68fB36d14565F523C9c36ab2A8Be0c26D55',
            min_deposit: 10,
            min_fee: 1.5,     
        },
        moonbeam:
        {
            tokenAddress: '0x61C82805453a989E99B544DFB7031902e9bac448',
            min_deposit: 10,
            min_fee: 1.5,     
        },                                        
    },
    FRAX:
    {
        denom: 'frax-wei',
        denom_name: 'axlFRAX',
        decimal: 18,
        allow_decimal: 2,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
            min_deposit: 50,
            min_fee: 20.5,
        },                             
    },    
    WBTC:
    {
        denom: 'wbtc-satoshi',
        denom_name: 'axlWBTC',
        decimal: 8,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
            min_deposit: 0.002,
            min_fee: 0.00102,
        },                             
    },
    xcDOT:
    {
        denom: 'dot-planck',
        denom_name: 'axlDOT',
        decimal: 10,
        allow_decimal: 4,
        type: 'token',
        moonbeam:
        {
            tokenAddress: '0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080',
            min_deposit: 0.5,
            min_fee: 0.17,
        },                             
    },
    axlATOM:
    {
        denom: 'uatom',
        denom_name: 'axlATOM',
        decimal: 6,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA',
            min_deposit: 10,
            min_fee: 3.1,
        },        
        avalanche:
        {
            tokenAddress: '0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12',
            min_deposit: 10,
            min_fee: 3.1,
        },
        polygon:
        {
            tokenAddress: '0x33F8a5029264BcFB66e39157aF3FeA3E2a8a5067',
            min_deposit: 10,
            min_fee: 3.1,    
        },
        fantom:
        {
            tokenAddress: '0x3bB68cb55Fc9C22511467c18E42D14E8c959c4dA',
            min_deposit: 10,
            min_fee: 3.1,     
        },
        moonbeam:
        {
            tokenAddress: '0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA',
            min_deposit: 10,
            min_fee: 3.1,     
        },                                        
    },
    RAI:
    {
        denom: 'rai-wei',
        denom_name: 'RAI',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919',
            min_deposit: 20,
            min_fee: 7.2,
        },                             
    },
    LINK:
    {
        denom: 'link-wei',
        denom_name: 'LINK',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
            min_deposit: 10,
            min_fee: 3.08,
        },                             
    },
    AAVE:
    {
        denom: 'aave-wei',
        denom_name: 'AAVE',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
            min_deposit: 1,
            min_fee: 0.308,
        },                             
    },
    APE:
    {
        denom: 'ape-wei',
        denom_name: 'APE',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
            min_deposit: 10,
            min_fee: 4.1,
        },                             
    },
    UNI:
    {
        denom: 'uni-wei',
        denom_name: 'UNI',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
            min_deposit: 10,
            min_fee: 4.1,
        },                             
    },
    SHIB:
    {
        denom: 'shib-wei',
        denom_name: 'SHIB',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
            min_deposit: 10000000,
            min_fee: 2050000,
        },                             
    },
    AXS:
    {
        denom: 'axs-wei',
        denom_name: 'AXS',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b',
            min_deposit: 10,
            min_fee: 1.03,
        },                             
    },
    XCN:
    {
        denom: 'xcn-wei',
        denom_name: 'XCN',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18',
            min_deposit: 1000,
            min_fee: 206,
        },                             
    },
    MKR:
    {
        denom: 'mkr-wei',
        denom_name: 'MKR',
        decimal: 18,
        allow_decimal: 4,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
            min_deposit: 1,
            min_fee: 0.0205,
        },                             
    },                                                                                                  
};
export const config = [

    {
        id: "kujira", values: 
        {
            "chainId": "kaiyo-1",
            "chainName": "Kujira",
            "rpc": "https://rpc-kujira.synergynodes.com",
            "rest": "https://lcd-kujira.synergynodes.com",
            "bip44": {
                "coinType": 118
            },
            "bech32Config": {
                "bech32PrefixAccAddr": "kujira",
                "bech32PrefixAccPub": "kujirapub",
                "bech32PrefixValAddr": "kujiravaloper",
                "bech32PrefixValPub": "kujiravaloperpub",
                "bech32PrefixConsAddr": "kujiravalcons",
                "bech32PrefixConsPub": "kujiravalconspub"
            },
            "currencies": [ 
                { 
                    "coinDenom": "KUJI", 
                    "coinMinimalDenom": "ukuji", 
                    "coinDecimals": 6, 
                    "coinGeckoId": "kujira"
                }
            ],
            "feeCurrencies": [
                { 
                    "coinDenom": "KUJI", 
                    "coinMinimalDenom": "ukuji", 
                    "coinDecimals": 6, 
                    "coinGeckoId": "kujira"
                }
            ],
            "stakeCurrency": { 
                    "coinDenom": "KUJI", 
                    "coinMinimalDenom": "ukuji", 
                    "coinDecimals": 6, 
                    "coinGeckoId": "kujira"
            },
            "coinType": 118,
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    },
    {
        id: "crescent", values: 
        {
            "chainId": "crescent-1",
            "chainName": "Crescent",
            "rpc": "https://crescent-rpc.polkachu.com",
            "rest": "https://crescent-api.polkachu.com",
            "bip44": {
                "coinType": "118"
            },
            "coinType": "118",
            "bech32Config": {
                "bech32PrefixAccAddr": "cre",
                "bech32PrefixAccPub": "crepub",
                "bech32PrefixValAddr": "crevaloper",
                "bech32PrefixValPub": "crevaloperpub",
                "bech32PrefixConsAddr": "crevalcons",
                "bech32PrefixConsPub": "crevalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "CRE",
                    "coinMinimalDenom": "ucre",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "CRE",
                    "coinMinimalDenom": "ucre",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "CRE",
                "coinMinimalDenom": "ucre",
                "coinDecimals": "6",
                "coinGeckoId": "unknown"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    }, 
    {
        id: "terra", values: 
        {
            "chainId": "phoenix-1",
            "chainName": "Terra V2",
            "rpc": "https://phoenix-rpc.terra.dev",
            "rest": "https://phoenix-lcd.terra.dev",
            "bip44": {
                "coinType": "330"
            },
            "coinType": "330",
            "bech32Config": {
                "bech32PrefixAccAddr": "terra",
                "bech32PrefixAccPub": "terrapub",
                "bech32PrefixValAddr": "terravaloper",
                "bech32PrefixValPub": "terravaloperpub",
                "bech32PrefixConsAddr": "terravalcons",
                "bech32PrefixConsPub": "terravalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "LUNA",
                    "coinMinimalDenom": "uluna",
                    "coinDecimals": "6",
                    "coinGeckoId": "terra-luna"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "LUNA",
                    "coinMinimalDenom": "uluna",
                    "coinDecimals": "6",
                    "coinGeckoId": "terra-luna"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "LUNA",
                "coinMinimalDenom": "uluna",
                "coinDecimals": "6",
                "coinGeckoId": "terra-luna"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    },        
    
];
import React, { useState, useEffect } from 'react';
import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import Token from './Token';
import Token_Swap from './Token_Swap';
import Token_Native from './Token_Native';
import { assets } from '../components/Assets/assets';
import { chains } from '../components/Assets/chains';

const info = {
  asset: '', type: '', from: '', to: '', tokenAddress: '', contractAddress: '', denom: '', denom_name: '', evmChain: '', keplrChainID: '', axelarFeeURL: '', evmExplorer: '', source_image: '', destination_image: '', decimal: '', allow_decimal: '', min_deposit: '', min_fee: '',
};

const Execute = () => {

  const { id } = useParams();

  const [chainID, setChainID] = useState();
  const [evmChain, setEVMChain] = useState();
  const [source_name, setSourceName] = useState();

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    provider.on("network", (newNetwork, oldNetwork) => {
        //console.log("oldNetwork = ", oldNetwork);
        //console.log("newNetwork = ", newNetwork);
        setChainID(newNetwork.chainId);
        if (oldNetwork) {
            window.location.reload();
        }
    });

    const getInfo = async () => {

      const values = id.split('-');      

      setEVMChain(chains[values[1]].evmChain);
      setSourceName(values[1].charAt(0).toUpperCase() + values[1].slice(1));

      info.asset = values[0];
      info.from = values[1];
      info.to = values[2];

      info.tokenAddress = assets[info.asset][info.from].tokenAddress;
      info.contractAddress = assets[info.asset][info.from].contractAddress;
      info.denom = assets[values[0]].denom;
      info.denom_name = assets[values[0]].denom_name;
      info.evmChain = chains[info.from].evmChain;
      info.keplrChainID = chains[info.to].keplrChainID;
      info.axelarFeeURL = 'https://lcd-axelar.synergynodes.com/axelar/nexus/v1beta1/transfer_fee?source_chain=' + info.from + '&destination_chain=' + info.to + '&amount=';
      info.evmExplorer = chains[info.from].evmExplorer;
      info.source_image = chains[info.from].image;
      info.destination_image = chains[info.to].image;
      info.decimal = assets[info.asset].decimal;
      info.allow_decimal = assets[info.asset].allow_decimal;
      info.min_deposit = assets[info.asset][info.from].min_deposit;
      info.min_fee = assets[info.asset][info.from].min_fee;

    }
    
    getInfo()
      .catch(console.error);    

  })

  return (
            <div>          
              {(() => {
                if (chainID == info.evmChain) {
                  if (info.asset == 'USDC' && info.from != 'ethereum')
                  {
                    return ( <Token_Swap info={info}/> )
                  }
                  if (info.asset == 'USDCe')
                  {
                    return ( <Token_Swap info={info}/> )
                  }                  
                  else if (info.asset == 'ETH' || info.asset == 'BNB' || info.asset == 'AVAX' || info.asset == 'MATIC' || info.asset == 'FTM' || info.asset == 'GLMR')
                  {
                    return ( <Token_Native info={info}/> )
                  }
                  else
                  {
                    return ( <Token info={info}/> )
                  }                  
                } else {
                  return (
                    <div className='network_wrong'>Wrong Network. Please choose <img src={info.source_image}></img> {source_name} mainnet in Metamask and reload this page.
                    </div>
                  )
                }
              })()}
            </div>
  );
}

export default Execute;
export const chains = {
    ethereum: 
    {
        evmChain: 1,
        evmExplorer: 'https://etherscan.io/tx/',
        image: '../images/ethereum.png',
    },
    binance: 
    {
        evmChain: 56,
        evmExplorer: 'https://bscscan.com/tx/',
        image: '../images/bnbchain.png',
    },    
    avalanche: 
    {
        evmChain: 43114,
        evmExplorer: 'https://snowtrace.io/tx/',
        image: '../images/avalanche.png',
    },
    polygon: 
    {
        evmChain: 137,
        evmExplorer: 'https://polygonscan.com/tx/',
        image: '../images/polygon.png',
    },
    fantom: 
    {
        evmChain: 250,
        evmExplorer: 'https://ftmscan.com/tx/',
        image: '../images/fantom.png',
    },
    moonbeam: 
    {
        evmChain: 1284,
        evmExplorer: 'https://moonbeam.moonscan.io/tx/',
        image: '../images/moonbeam.png',
    },    
    kujira:
    {
        keplrChainID: 'kaiyo-1',
        image: '../images/kujira.png',
    },
    osmosis:
    {
        keplrChainID: 'osmosis-1',
        image: '../images/osmosis.png',
    },
    cosmoshub:
    {
        keplrChainID: 'cosmoshub-4',
        image: '../images/cosmos.png',
    },
    axelar:
    {
        keplrChainID: 'axelar-dojo-1',
        image: '../images/axelar.svg',
    },
    crescent:
    {
        keplrChainID: 'crescent-1',
        image: '../images/crescent.png',
    },
    terra:
    {
        keplrChainID: 'phoenix-1',
        image: '../images/Terra-V2.jpg',
    },
    emoney:
    {
        keplrChainID: 'emoney-3',
        image: '../images/emoney.svg',
    },
    juno:
    {
        keplrChainID: 'juno-1',
        image: '../images/juno.png',
    },
    secret:
    {
        keplrChainID: 'secret-4',
        image: '../images/secret.png',
    },                                    
};
import React from 'react';

const Smart_Contracts = () => {
return (
	<div className='forms smart_contracts'>
	<h3>Smart Contract Details & Links</h3>
    <h4>CosmosBridge.app Smart Contracts</h4>
		<p>Ethereum : <a href="https://etherscan.io/address/0x1b7239ab106c7bac8176607a33f57f6f02007ade#code" target="_blank">0x1B7239aB106C7bAc8176607A33F57F6f02007aDe</a></p>
        <p>Binance : <a href="https://bscscan.com/address/0xd9256999ccacdb685d17b4a196219666d576c8cb#code" target="_blank">0xd9256999ccacdb685d17b4a196219666d576c8cb</a></p>        
        <p>Avalanche : <a href="https://snowtrace.io/address/0x1B7239aB106C7bAc8176607A33F57F6f02007aDe#code" target="_blank">0x1B7239aB106C7bAc8176607A33F57F6f02007aDe</a></p>
        <p>Polygon : <a href="https://polygonscan.com/address/0x1B7239aB106C7bAc8176607A33F57F6f02007aDe#code" target="_blank">0x1B7239aB106C7bAc8176607A33F57F6f02007aDe</a></p>
        <p>Fantom : <a href="https://ftmscan.com/address/0x1B7239aB106C7bAc8176607A33F57F6f02007aDe#code" target="_blank">0x1B7239aB106C7bAc8176607A33F57F6f02007aDe</a></p>
        <p>Moonbeam : <a href="https://moonbeam.moonscan.io/address/0x1B7239aB106C7bAc8176607A33F57F6f02007aDe#contracts" target="_blank">0x1B7239aB106C7bAc8176607A33F57F6f02007aDe</a></p>

        <p>Audit Report : <a href="https://solidity.finance/audits/SynergyNodes/" target="_blank">Solidity Finance</a></p>

    <hr></hr>

	<h4>Curve Finance Smart Contracts</h4>
        <p>Avalanche : <a href="https://snowtrace.io/address/0xbb8a6436e0E9A22bb7F1dC76aFB4421D8195620E#code" target="_blank">0xbb8a6436e0E9A22bb7F1dC76aFB4421D8195620E</a></p>
        <p>Polygon : <a href="https://polygonscan.com/address/0xfBA3b7Bb043415035220b1c44FB4756434639392#code" target="_blank">0xfBA3b7Bb043415035220b1c44FB4756434639392</a></p>
        <p>Fantom : <a href="https://ftmscan.com/address/0x261036923F4aAF1322a3173921fCBdaE51352CAe#code" target="_blank">0x261036923F4aAF1322a3173921fCBdaE51352CAe</a></p>  

	</div>
);
};

export default Smart_Contracts;

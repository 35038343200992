export const chain_assets =
    {
        ethereum_assets:
        [
            {id: 'USDC', name: <span className="yellow"><img src="../images/usdc.png"></img> USDC</span>},
            {id: 'USDT', name: <span className="yellow"><img src="../images/usdt.png"></img> USDT</span>},
            {id: 'ETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> ETH</span>},
            {id: 'WETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> WETH</span>},            
            {id: 'DAI', name: <span className="yellow"><img src="../images/dai.png"></img> DAI</span>},
            {id: 'FRAX', name: <span className="yellow"><img src="../images/frax.png"></img> FRAX</span>},
            {id: 'WBTC', name: <span className="yellow"><img src="../images/wbtc.png"></img> WBTC</span>},
            {id: 'RAI', name: <span className="yellow"><img src="../images/rai.png"></img> RAI</span>},
            {id: 'LINK', name: <span className="yellow"><img src="../images/link.png"></img> LINK</span>},
            {id: 'AAVE', name: <span className="yellow"><img src="../images/aave.png"></img> AAVE</span>},
            {id: 'APE', name: <span className="yellow"><img src="../images/ape.png"></img> APE</span>},
            {id: 'UNI', name: <span className="yellow"><img src="../images/uni.png"></img> UNI</span>},
            {id: 'SHIB', name: <span className="yellow"><img src="../images/shib.png"></img> SHIB</span>},
            {id: 'AXS', name: <span className="yellow"><img src="../images/axs.png"></img> AXS</span>},
            {id: 'XCN', name: <span className="yellow"><img src="../images/xcn.png"></img> XCN</span>},
            {id: 'MKR', name: <span className="yellow"><img src="../images/mkr.png"></img> MKR</span>},
        ],
        binance_assets:
        [                      
            {id: 'axlUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlaUSDC</span>},
            {id: 'axlUSDT', name: <span className="yellow"><img src="../images/usdt.png"></img> axlUSDT</span>},
            {id: 'BNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> BNB</span>},
            {id: 'WBNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> WBNB</span>},
            {id: 'axlDAI', name: <span className="yellow"><img src="../images/dai.png"></img> axlDAI</span>},
            {id: 'axlFRAX', name: <span className="yellow"><img src="../images/frax.png"></img> axlFRAX</span>},
        ],         
        avalanche_assets:
        [
            {id: 'USDCe', name: <span className="yellow"><img src="../images/usdc.png"></img> USDCe</span>},
            {id: 'AVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> AVAX</span>},
            {id: 'WAVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> WAVAX</span>},            
            {id: 'axlUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlUSDC</span>},
            {id: 'axlUSDT', name: <span className="yellow"><img src="../images/usdt.png"></img> axlUSDT</span>},
            {id: 'axlDAI', name: <span className="yellow"><img src="../images/dai.png"></img> axlDAI</span>},
            {id: 'axlFRAX', name: <span className="yellow"><img src="../images/frax.png"></img> axlFRAX</span>},
        ],
        polygon_assets:
        [
            {id: 'USDC', name: <span className="yellow"><img src="../images/usdc.png"></img> USDC</span>},
            {id: 'MATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> MATIC</span>},
            {id: 'WMATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> WMATIC</span>},            
            {id: 'axlUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlUSDC</span>},
            {id: 'axlUSDT', name: <span className="yellow"><img src="../images/usdt.png"></img> axlUSDT</span>},
            {id: 'axlDAI', name: <span className="yellow"><img src="../images/dai.png"></img> axlDAI</span>},
            {id: 'axlFRAX', name: <span className="yellow"><img src="../images/frax.png"></img> axlFRAX</span>},
        ],
        fantom_assets:
        [
            {id: 'USDC', name: <span className="yellow"><img src="../images/usdc.png"></img> USDC</span>},
            {id: 'FTM', name: <span className="yellow"><img src="../images/fantom.png"></img> FTM</span>},
            {id: 'WFTM', name: <span className="yellow"><img src="../images/fantom.png"></img> WFTM</span>},            
            {id: 'axlUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlUSDC</span>},
            {id: 'axlUSDT', name: <span className="yellow"><img src="../images/usdt.png"></img> axlUSDT</span>},
            {id: 'axlDAI', name: <span className="yellow"><img src="../images/dai.png"></img> axlDAI</span>},
            {id: 'axlFRAX', name: <span className="yellow"><img src="../images/frax.png"></img> axlFRAX</span>},
        ],
        moonbeam_assets:
        [
            {id: 'GLMR', name: <span className="yellow"><img src="../images/moonbeam.png"></img> GLMR</span>},
            {id: 'WGLMR', name: <span className="yellow"><img src="../images/moonbeam.png"></img> WGLMR</span>},            
            {id: 'axlUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlUSDC</span>},
            {id: 'axlUSDT', name: <span className="yellow"><img src="../images/usdt.png"></img> axlUSDT</span>},
            {id: 'axlDAI', name: <span className="yellow"><img src="../images/dai.png"></img> axlDAI</span>},
            {id: 'axlFRAX', name: <span className="yellow"><img src="../images/frax.png"></img> axlFRAX</span>},
        ],                                     
    };